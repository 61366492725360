import styled from "styled-components";
import {Flex} from "../Flex";
import {Row} from "./Row";
import {Column} from "./Column";

export interface ITableProps {
  color?: string;
}

export const Table = styled(Flex)`
  flex-direction: column;
  min-width: 30rem;
  border: 0.2rem solid ${(props) => props.color || "black"};
  color: #fff;
  & > ${Row}:nth-of-type(1) {
    background: ${(props) => props.color || "black"};
    font-weight: bold;
  }

  & > ${Row} {
    border-bottom: 0.2rem solid ${(props) => props.color || "black"};

    :last-of-type {
      border-bottom: none;
    }
  }

  & > ${Row} > ${Column}:nth-of-type(1) {
    padding: 1rem;
    width: 50%;
    border-right: 0.2rem solid ${(props) => props.color || "black"};
  }
`;
