export enum SlideEnum {
    Index,
    WesternAustralia,
    NorthernTerritory,
    Queensland,
    NewSouthWales,
    Victoria,
    Tasmania,
    SouthAustralia,
    AustralianCapitalTerritory,
}