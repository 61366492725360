import React from "react";
import "./App.css";
import { InteractiveMap } from "./components/InteractiveMap/InteractiveMap";
import { Footer } from "./components/Footer/Footer";
import { generateRenderable, generateRoutes } from "./routes";
import { useRoutes } from "react-router-dom";
import { SlideConfig } from "./SlideConfig";
import { SliderProvider } from "./providers/SliderProvider";
import { Grid, GridItem } from "./components/Grid";
import { Flex } from "./components/Flex";

function App() {
  const childRoutes = generateRenderable(SlideConfig);

  const renderable = useRoutes(generateRoutes(childRoutes));
  return (
    <Grid columns={1} rows={3} fullHeight>
      <GridItem startColumn={1} endColumn={1} startRow={2} endRow={2}>
        <Flex>
          <SliderProvider slides={SlideConfig}>
            <Flex flexStyles={{ width: "50vw" }} justifyContent={"center"} flexGrow={1}>
              <InteractiveMap />
            </Flex>
            <Flex flexStyles={{ width: "50vw" }} justifyContent={"center"} flexGrow={1}>
              {renderable}
            </Flex>
          </SliderProvider>
        </Flex>
      </GridItem>
      <GridItem startColumn={1} endColumn={1} startRow={3} endRow={3}>
        <Flex justifyContent={"flex-end"} flexDirection={"column"} fillHeight>
          <Footer />
        </Flex>
      </GridItem>
    </Grid>
  );
}

export default App;
