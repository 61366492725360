import { Flex, IFlexStyles } from "../Flex";
import { ITextStyles, Text } from "../Text";
import React from "react";
import { Header } from "../Header/Header";

const textStyles = {
  color: "#fff",
} as ITextStyles;

const footerStyles = {
  backgroundColor: "#000",
  paddingLeft: "1rem",
    paddingBottom: "1rem",
} as IFlexStyles;

export function Footer() {
  return (
    <Flex flexStyles={footerStyles}  justifyContent={'space-between'} alignItems={'center'}>
      <Flex flexGap={"1rem"}>
        <Text fontStyles={textStyles}>Privacy Policy</Text>
        <Text fontStyles={textStyles}>Website by The Mark Agency</Text>
      </Flex>
      <Header />
    </Flex>
  );
}
