import Logo from "../../images/logo-black.svg";
import React from "react";
import styled from "styled-components";

const InvertedImage = styled.img`
    filter: invert(1);
  margin-right: 1rem;
`;

export function Header() {
    return <InvertedImage width="145" height="45" src={Logo} alt="logo"/>;
}