import {Svg} from "./Svg";
import {Group} from "./Group";
import {Path} from "./Path";
import React from "react";
import {useSlideContext} from "../../hooks/useSlideContext";
import {SlideEnum} from "../Slides/SlideEnum";

export function InteractiveMap() {
    const {goToSlide} = useSlideContext();
    return (
        <Svg xmlns="http://www.w3.org/2000/svg" id="AustraliaMap" version="1.1" viewBox="-1550 -1050 4000 5000"
             width="900" height="800" preserveAspectRatio="xMidYMin">
            <Group id="AustraliaMapSvgGroup" transform="scale(1.8)">
                <Group id="WesternAustralia">
                    <Path
                        onClick={() => goToSlide(SlideEnum.WesternAustralia)}
                        id="WesternAustralia"
                        backgroundColor="#b3b3b3"
                        hoverColor="#8EC5D8"
                        d="m 141,46.362183 -32,-6 -13,-4 -31,-28.0000004 -17,-7 -13,-7 -11,0 -8,6.00000002 3,10.00000038 -7,4 -4,-4 -22,8 -9,23 -11,-7 -23,11 -9,13 11,12 -3,0 -9,3 2,18 -13,-3 -16,12.999997 -3,8 2,10 5,8 -9,11 3,8 -19,-2 -12,-3 -9,3 3,8 -4,13 15,16 -3,10 -6,-6 -4,28 c 0,0 -10,-5 -13,-9 -3,-4 -19,-40 -19,-40 l 0,-5 -7,4 -20,24 -12,15 -2,16 6,17 6,14 -7,11 -20,18 -11,37 -13,17 -31,18 -10,8 -27,6 -8,7 -28,-6 -9,17 -5,4 -30,8 -7,6 -7,12 -20,7 -21,-3 -17,2 -14,9 -21,23 -19,24 -28,14 -11,10 -5,32 -9,5 -7,0 -4,-31 -8,2 -10,39 12,17 1,11 -3,27 -7,17 0,27 14,15 9,20 21,23 13,16 1,24 -15,-8 -28,-29 -3,13 17,17 10,14 -23,-3 4,9 32,35 12,21 3,17 32,38 17,25 5,27 1,16 12,26 30,43.00002 7,17 5,44 3,23 -1,13 -5,17 -7,2 -15,-6 0,11 3,24 6,10 11,-4 21,7 17,16 15,6 28,1 28,-3 29,-11 9,-8 14,-19 15,-5 11,-5 3,-16 19,-11 43,-7 27,-5 23,5 38,-7 7,0 9,2 18,-9 12,-17 5,-26 7,-5 20,-4 57,-37.00002 18,-3 38,2 21,-12 34,-18 18,-8 -34,-905.999997 z"
                    />
                </Group>

                <Group id="NorthernTerritory">
                    <Path
                        backgroundColor="#e6e6e6"
                        hoverColor="#e6e6e6"
                        d="m 150,54.362183 15,-3 15,4 1,-13 -15,-15 -1,-10 6,-11.0000004 11,-1 6,-30.9999996 9,3 11,-14 -6,-12 0,-10 9,0 3,-9 12,-7 2,-14 15,0 8,-11 21,6 22,-3 12,4 17,-10.000003 15,-4 -4,-20 -10,-9 -14,-2 -10,-11 24,-10 20,22 10,-7 12,19 24,6 13,-7 2,9 44,8 6,1 15,12.000003 8,1 8,-8.000003 14,2 3,7.000003 13,-3 6,13 9,1 7,-10 -7,-8.000003 14,-7 3,0 6,9.000003 7,2 5,2 5,8 -10,11 -7,11 -8,1 2,13 -6,9 -9,-3 -20,13 0,17 6,5.9999996 -9,16 1,5.0000004 -9,7 -12,20 -4,3 4,14 38,25 4,8 17,9 4,12.999997 19,-3 20,13 16,8 8,15 -18,505 -98,-3 -123,-2 -163,5 -44,1 z"
                    />
                    <Path backgroundColor="#b3b3b3" hoverColor=""
                          d="m 193,-116.63782 20,4 16,-6 15,4 22,-13 8,-11 -1,-12 -11,-3 -27,10 -20,-6 -7,5 -9,11 1,5 z"/>
                    <Path backgroundColor="#b3b3b3" hoverColor=""
                          d="m 536,17.362183 29,2 6,-7 -10,-8.0000004 3,-14 -20,-6.9999996 -2,6.9999996 -4,20.0000004 z"/>
                </Group>
                <Group id="Nsw">
                    <Path
                        onClick={() => goToSlide(SlideEnum.NewSouthWales)}
                        backgroundColor="#b3b3b3"
                        hoverColor="#1FC4F4"
                        d="m 739,825.36218 146,12 107,10 120,13 15,-15 12,-3 10,3 21,-1 7,5 6,2 11,2 7,5 5,4 7,8 1,10 7,2 8,-4 5,-9 9,5 11,0 4,-10 -1,-9 24,-11 11,3 10,5 24,-5 -1,26 -13,24 -11,31 -13,29 0,18 -9,21 -11,24.00002 -10,12 -8,12 -5,14 -36,23 -28,33 -9,22 -11,20 -9,30 -13,13 -18,19 -10,25 -8,24 -6,36 -5,6 -66,-43 1,-15 -4,-10 0,-17 -18,-8 -21,3 -19,-4 -23,-1 -27,-9 -17,-7 -15,3 -1,6 1,6 -3,1 -7,-4 -11,-18 -12,-13 -16,-9 -5,-9 -7,-8 4,-17 -10,-4.5 -18.5,-6 -8.5,6.5 -7,-16 -1,-9 -19,-13 -9,-3 -9,5 -21,-8 z"
                    />
                </Group>
                <Group id="Victoria">
                    <Path
                        onClick={() => goToSlide(SlideEnum.Victoria)}
                        backgroundColor="#b3b3b3"
                        hoverColor="#F37D1E"
                        d="m 721,1096.3622 -12,215 24,17 12,-8 17,7 26,14 22,16 43,-20 9,-8 13,-19 6,11 -6,14 -2,6 24,14 14,9 4,7 38,-8 31,-28 27,-9 34,-1 31,2 12,-12 -68,-45 -3,-8 3,-12 -4,-4 -2,-16 -9,-3 -12,-2 -9,4 -13,-4 -9,-4 -12,5 -9,-4 -29,-10 -10,-4 -12,0 -2,11 -6,3 -9.5,-1 -9.5,-15 -8,-14 -15,-12 -14,-5 -7.5,-13.5 -1,-14.5 -3,-10 -10,-3 -8.5,-2 -8,7 -8,-12 -5,-7 0,-9 -17,-10 -9,3 -11,-2 z"
                    />
                    <Path
                        id="ACT"
                        onClick={() => goToSlide(SlideEnum.AustralianCapitalTerritory)}
                        hoverColor={"#E11921"}
                        strokeColor={"#fff"}
                        backgroundColor={"#b3b3b3"}
                        d={"m 1057,1200.3622 9,-14 12,0 4,11 -9,11 -5,20 -9,-10 -3,-10 z"}
                    />
                </Group>
                <Group id="Tasmania">
                    <Path
                        backgroundColor="#e6e6e6"
                        hoverColor="#e6e6e6"
                        d="m 924,1631.8622 9.5,-16 8,-3 10.5,-20.5 4.5,0 12,2 6,-7.5 8.5,-29 10,-9.5 2.5,-22.5 1,-19 4.5,-13 c 0,0 -10,-14 -12,-14 -2,0 -14.5,8.5 -14.5,8.5 l -26,0.5 -11,3 -18,4.5 -31,-15 -32,-16.5 -4.5,0.5 -3,10.5 -2.5,10.5 7.5,23.5 7.5,17.5 8.5,16 3.5,14 -3,9.5 -0.5,7.5 14.5,32 7.5,17 6,3 11,-1 10.5,6.5 z"
                    />
                    <Path backgroundColor="#b3b3b3" hoverColor=""
                          d="m 985,1428.3622 6,-6 3.5,-0.5 6.5,9.5 5.5,10 -0.5,12.5 -9,2.5 -6.5,-11 -4.5,-12 z"/>
                    <Path backgroundColor="#b3b3b3" hoverColor=""
                          d="m 823.5,1395.3622 -4.5,12 0,15.5 3.5,5.5 4.5,1 4.5,-5.5 4,-12 -0.5,-9.5 -1.5,-4 -3,-4 z"/>
                </Group>
                <Group id="Queensland">
                    <Path
                        onClick={() => goToSlide(SlideEnum.Queensland)}
                        backgroundColor="#b3b3b3"
                        hoverColor="#001E5E"
                        d="m 625,149.36218 15,5 22,8 12,9 10,21 18,7 19,15 17,-4 17,-2 11,-15 12,-21 13,-17 9,-30 2,-19 13,-33.999997 -6,-16 5,-27 -3,-12 -5,-12.0000004 8,-15 8,-11.9999996 -2,-22 10,-5 7,-9 -13,-12 22,-34.000003 8,-34 3,-9 11,-6 10,-10 5,2 -1,7 12,10 1,43 6,7.000003 8,5 -8,14 17,20 0,8 5,15 -3,35.9999996 7,25.0000004 4,18 12,6 13,-12 16,-3 4,17 21,18 10,8 -1,28.999997 5,24 -1,15 -2,14 23,48 7,13 -1,22 -7,16 13,5 -3,24 -1,10 14,17 21,14 21,2 4,12 4,14 23,4 10,21 9,-7 16,15 -5,12 11,24 11,25 4,38 0,20 14,3 4,4 2,-22 9,6 22,24 9,16 -5,32 24,33 14,3 9,14 3,17 18,13 4,13 5,13 8,7 -1,17 8,14 -2,7 -6,9 1,39 -1,10 -8,-1 5,13 9,20 -2,25 -24,2 -20,-8 -30,16 -2,15 -12,-1 -5,-1 -9,11 -5,1 2,-10 -7,-8 -5,-7 -13,-5 -13,0 -6,-10 -24,4 -15,-8 -14,5 -14,14 -157,-15 -86,-10 -117,-8 -11,-1 11,-160 -142,-7 z"
                    />
                    <Path backgroundColor="#b3b3b3" hoverColor=""
                          d="m 682.5,156.86218 6.5,1 8,-2.5 11,-5.5 5.5,-1 1.5,-5.5 -2,-4.5 -15,-2.5 -8,2.5 -6.5,5 -4,5.5 -0.5,5.5 z"/>
                    <Path backgroundColor="#b3b3b3" hoverColor=""
                          d="m 1329,683.36218 3,14 7,2 9,-13 4,-18 -11,-8 -7,5 z"/>
                </Group>
                <Group id="SouthAustralia">
                    <Path
                        onClick={() => goToSlide(SlideEnum.SouthAustralia)}
                        backgroundColor="#b3b3b3"
                        hoverColor="#FABF11"
                        d="m 700.5,1306.8622 -14,-2 -10.5,-12 -19,-34.5 -4,-13 4,-10 -5,-22 -3.5,-21.5 -13.5,-12 -14.5,-12 -11,1.5 -6.5,2 -13,-1.5 1,-6.5 12.5,-21 2.5,-11 -0.5,-11 -16.5,-26 -3.5,10 -11,37.5 -8.5,4 -23.5,0 -5,-11.5 5.5,-4 12.5,-0.5 2,-8 3,-26 5.5,-20 14,-12 3.5,-6 -0.5,-16 6.5,-3.5 2,-2.5 -11.5,-32 -4.5,1 -1,18 -7,7.5 -12.5,21.5 -6,12 -12,2 -18,12.5 -13.5,19.5 -11.5,18.5 -11.5,8.5 -11,-4.5 -11.5,-38.5 -0.5,-10 -14,-13.5 -9.5,-21.5 -12.5,-4.5 -9,-9 -4,-7.5 6,-11 -10,-5.50002 -5,-7.5 -6.5,-10.5 -9.5,-4.5 -15.5,5 -8,-6 -10.5,-5.5 -10.5,-2 -5,4 -8,0 -12,-8.5 -18,-11 -15.5,-7.5 -5.5,-1 -4.5,1 -7.5,4 -23.5,0.5 -58,4 -12.5,-297 181,-3.5 142.5,0.5 146.5,6 100,4 -37.5,644.00002 z"
                    />
                    <Path backgroundColor="#b3b3b3" hoverColor=""
                          d="m 527,1164.3622 -1,11 8,6 10,0 28,-2 10,-10 -10,-11 -10,0 z"/>
                </Group>
            </Group>
        </Svg>
    );
}
