import {Frame, IFrameProps} from "../Frames/Frame";
import {Table} from "../Table/Table";
import React from "react";
import {Row} from "../Table/Row";
import {Column} from "../Table/Column";

export function Nsw(props: IFrameProps) {
  return (
      <Frame>
        <Table color={"#1FC4F4"}>
          <Row>
            <Column>State / Territory</Column>
            <Column>New South Wales</Column>
          </Row>
          <Row>
            <Column>Current Store Numbers</Column>
            <Column>189</Column>
          </Row>
          <Row>
            <Column>Market “Gaps”</Column>
            <Column>70</Column>
          </Row>
          <Row>
            <Column>High Priority Areas</Column>
            <Column>15</Column>
          </Row>
          <Row>
            <Column>Medium Priority Areas</Column>
            <Column>25</Column>
          </Row>
          <Row>
            <Column>Low Priority Areas</Column>
            <Column>15</Column>
          </Row>
          <Row>
            <Column>Future Growth Corridors</Column>
            <Column>15</Column>
          </Row>
          <Row>
            <Column>Committed Stores</Column>
            <Column>8</Column>
          </Row>
          <Row>
            <Column>Forecast Store Closures</Column>
            <Column>3</Column>
          </Row>
          <Row>
            <Column>2030 (Plus) Projected Store Numbers</Column>
            <Column>264</Column>
          </Row>
          <Row>
            <Column>Growth as %</Column>
            <Column>32.26%</Column>
          </Row>
          <Row>
            <Column>Target Stores # per state</Column>
            <Column>6</Column>
          </Row>
        </Table>
      </Frame>
  );
}
