import React from 'react';

export interface ISlideContext {
  slideName?: string;
  nextSlide: () => void;
  prevSlide: () => void;
  currentSlide: number;
  slideCount: number;
  goToSlide: (slide: number) => void;
}

const SliderContext: React.Context<ISlideContext | undefined> =
    React.createContext<ISlideContext | undefined>(undefined);

export default SliderContext;
