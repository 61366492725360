import styled from "styled-components";
import {FontSize, FontWeight} from "./Text.enums";
import {toCSS} from "../Tools/toCSS";

export type ITextStyles = Partial<CSSStyleDeclaration>;

export interface IText {
  fontSize?: FontSize | string;
  fontWeight?: FontWeight;
  fontStyles?: ITextStyles;
  children: React.ReactElement[] | React.ReactElement | string;
}

export const Text = styled.span<IText>`
  display: flex;
  padding: 2px 0;
  font-size: ${(props) => props.fontSize ?? FontSize.small};
  font-weight: ${(props) => props.fontWeight ?? FontWeight.medium};
  flex-direction: column;

  ${(props) => props.fontStyles && toCSS(props.fontStyles)}
  color: ${(props) => props.fontStyles && props.fontStyles.color ? `${props.fontStyles.color};` : '#fff'};

  & > p {
    margin: 0.45rem 0;
  }
`;

export const Highlight = styled.span`
    color: #ff0000;
    font-weight: bold;
`;