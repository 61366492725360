import { Frame, IFrameProps } from "../Frames/Frame";
import { Table } from "../Table/Table";
import React from "react";
import { Row } from "../Table/Row";
import { Column } from "../Table/Column";

export function SouthAustralia(props: IFrameProps) {
  return (
    <Frame>
      <Table color={"#FABF11"}>
        <Row>
          <Column>State / Territory</Column>
          <Column>South Australia</Column>
        </Row>
        <Row>
          <Column>Current Store Numbers</Column>
          <Column>45</Column>
        </Row>
        <Row>
          <Column>Market “Gaps”</Column>
          <Column>10</Column>
        </Row>
        <Row>
          <Column>High Priority Areas</Column>
          <Column>1</Column>
        </Row>
        <Row>
          <Column>Medium Priority Areas</Column>
          <Column>2</Column>
        </Row>
        <Row>
          <Column>Low Priority Areas</Column>
          <Column>0</Column>
        </Row>
        <Row>
          <Column>Future Growth Corridors</Column>
          <Column>2</Column>
        </Row>
        <Row>
          <Column>Committed Stores</Column>
          <Column>2</Column>
        </Row>
        <Row>
          <Column>Forecast Store Closures</Column>
          <Column>0</Column>
        </Row>
        <Row>
          <Column>2030 (Plus) Projected Store Numbers</Column>
          <Column>19</Column>
        </Row>
        <Row>
          <Column>Growth as %</Column>
          <Column>4.61%</Column>
        </Row>
        <Row>
          <Column>Target Stores # per state</Column>
          <Column>1</Column>
        </Row>
      </Table>
    </Frame>
  );
}
