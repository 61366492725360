import {ReactElement} from "react";
import {IFrameProps} from "./components/Frames/Frame";
import {Introduction} from "./components/Slides/Introduction";
import {WesternAustralia} from "./components/Slides/WesternAustralia";
import {Victoria} from "./components/Slides/Victoria";
import {Nsw} from "./components/Slides/Nsw";
import {SouthAustralia} from "./components/Slides/SouthAustralia";
import {Queensland} from "./components/Slides/Queensland";
import {ACT} from "./components/Slides/ACT";
import {NorthernTerritory} from "./components/Slides/NorthernTerritory";
import {Tasmania} from "./components/Slides/Tasmania";

export const SlideConfig = [
  <Introduction name={"Introduction"}/>,
  <WesternAustralia name={"Western Australia"}/>,
  <NorthernTerritory name={"Northern Territory"}/>,
  <Queensland name={"Queensland"}/>,
  <Nsw name={"New South Wales"}/>,
  <Victoria name={"Victoria"}/>,
  <Tasmania name={"Tasmania"}/>,
  <SouthAustralia name={"South Australia"}/>,
  <ACT name={"Australian Capital Territory"}/>,
] as ReactElement<IFrameProps>[];
