import {Frame, IFrameProps} from "../Frames/Frame";
import {Table} from "../Table/Table";
import React from "react";
import {Row} from "../Table/Row";
import {Column} from "../Table/Column";

export function Victoria(props: IFrameProps) {
  return (
      <Frame>
        <Table color={"#F37D1E"}>
          <Row>
            <Column>State / Territory</Column>
            <Column>Victoria</Column>
          </Row>
          <Row>
            <Column>Current Store Numbers</Column>
            <Column>166</Column>
          </Row>
          <Row>
            <Column>Market “Gaps”</Column>
            <Column>56</Column>
          </Row>
          <Row>
            <Column>High Priority Areas</Column>
            <Column>11</Column>
          </Row>
          <Row>
            <Column>Medium Priority Areas</Column>
            <Column>18</Column>
          </Row>
          <Row>
            <Column>Low Priority Areas</Column>
            <Column>5</Column>
          </Row>
          <Row>
            <Column>Future Growth Corridors</Column>
            <Column>21</Column>
          </Row>
          <Row>
            <Column>Committed Stores</Column>
            <Column>12</Column>
          </Row>
          <Row>
            <Column>Forecast Store Closures</Column>
            <Column>0</Column>
          </Row>
          <Row>
            <Column>2030 (Plus) Projected Store Numbers</Column>
            <Column>234</Column>
          </Row>
          <Row>
            <Column>Growth as %</Column>
            <Column>25.81%</Column>
          </Row>
          <Row>
            <Column>Target Stores # per state</Column>
            <Column>5</Column>
          </Row>
        </Table>
      </Frame>
  );
}
