import styled from "styled-components";

export interface IPath {
    hoverColor: string;
    backgroundColor: string;
    strokeColor?: string;
}

export const Path = styled.path<IPath>`
  stroke: ${(props) => props.strokeColor ? props.strokeColor : "none"};
  stroke-width: 5;
  ;
 
  fill: ${(props) => props.backgroundColor};

  &:hover {
    fill: ${(props) => props.hoverColor};
    ${(p) => p.onClick ? "cursor: pointer;" : "cursor: not-allowed;"}

  }

  transition: fill 0.2s ease-in-out;
`;
