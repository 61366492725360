/**
 * This enum provides our definitions of standard font weights
 */
export enum FontWeight {
    lightest = 300,
    light = 400,
    medium = 500,
    heavy = 600,
}

/**
 * This enum provides a list of sensible font sizes for various situations.
 * You're of course free to eject and use your own size, your PR will be rejected
 * if it looks stupid.
 */
export enum FontSize {
    tiny = "8px",
    xSmall = "10px",
    small = "12px",
    medium = "14px",
    large = "18px",
    xLarge = "24px",
    xxLarge = "36px",
}
