import {Flex} from "../Flex";
import styled from "styled-components";

export const Column = styled(Flex)`
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  min-width: 50%;
  text-align: center;
`;
