import styled, {keyframes} from "styled-components";
import {Flex} from "../Flex";

const FrameAnimationForward = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`;

export interface IFrameProps {
  name: string;
}

export const Frame = styled(Flex)`
  transform: scale(0);
  animation-name: ${FrameAnimationForward};
  animation-duration: 500ms;
  animation-iteration-count: 1;
  transition: transform 500ms;
  animation-fill-mode: forwards;
`;


