import {Frame, IFrameProps} from "../Frames/Frame";
import {Table} from "../Table/Table";
import React from "react";
import {Row} from "../Table/Row";
import {Column} from "../Table/Column";

export function WesternAustralia(props: IFrameProps) {
  return (
      <Frame>
        <Table color={"#8EC5D8"}>
          <Row>
            <Column>State / Territory</Column>
            <Column>Western Australia</Column>
          </Row>
          <Row>
            <Column>Current Store Numbers</Column>
            <Column>52</Column>
          </Row>
          <Row>
            <Column>Market “Gaps”</Column>
            <Column>20</Column>
          </Row>
          <Row>
            <Column>High Priority Areas</Column>
            <Column>4</Column>
          </Row>
          <Row>
            <Column>Medium Priority Areas</Column>
            <Column>4</Column>
          </Row>
          <Row>
            <Column>Low Priority Areas</Column>
            <Column>7</Column>
          </Row>
          <Row>
            <Column>Future Growth Corridors</Column>
            <Column>2</Column>
          </Row>
          <Row>
            <Column>Committed Stores</Column>
            <Column>0</Column>
          </Row>
          <Row>
            <Column>Forecast Store Closures</Column>
            <Column>1</Column>
          </Row>
          <Row>
            <Column>2030 (Plus) Projected Store Numbers</Column>
            <Column>71</Column>
          </Row>
          <Row>
            <Column>Growth as %</Column>
            <Column>9.22%</Column>
          </Row>
          <Row>
            <Column>Target Stores # per state</Column>
            <Column>2</Column>
          </Row>
        </Table>
      </Frame>
  );
}
