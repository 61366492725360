import {useContext} from 'react';
import SliderContext from "../context/SliderContext";

export function useSlideContext() {
  const context = useContext(SliderContext);

  if (context === undefined) {
    throw new Error('useSlideContext must be used within a SlideProvider');
  }
  return context;
}
