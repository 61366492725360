import {useSliderNavigation} from "../hooks/useSliderNavigation";
import {IFrameProps} from "../components/Frames/Frame";
import React from "react";
import SliderContext from "../context/SliderContext";


export interface ISliderProvider {
  children: React.ReactNode;
  slides: React.ReactElement<IFrameProps>[];
}

export const SliderProvider = ({children, slides}: ISliderProvider) => {
  const {prevSlide, nextSlide, currentSlide, slideName, slideCount, goToSlide} = useSliderNavigation(slides);

  return <SliderContext.Provider value={{
    slideName,
    nextSlide,
    slideCount,
    prevSlide,
    currentSlide,
    goToSlide
  }}>{children}</SliderContext.Provider>;
};
