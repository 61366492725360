import {Frame, IFrameProps} from "../Frames/Frame";
import {FontSize, Text} from "../Text";
import React from "react";
import {Highlight} from "../Text/Text";

export function Introduction(props: IFrameProps) {
    return (
        <Frame flexDirection={"column"} flexShrink={1}>
            <Text fontStyles={{color: "rgb(250, 191, 17)"}} fontSize={FontSize.xxLarge}>
                ALDI AUSTRALIA - NATIONAL GROWTH
            </Text>
            <Text fontSize={FontSize.large}>
                <p>ALDI operates about 12,000 stores worldwide</p>
                <p>ALDI opened its first Australian store in 2001</p>
                <p>
                    Australia’s big supermarket story is ALDI Australia growing market share – ALDI's market share of
                    the total grocery market is approximately {" "}
                    <Highlight>10% with 590 Stores across Australia</Highlight>
                </p>
            </Text>
            <Text fontSize={FontSize.large}>
                <p>In comparison</p>

                <ul>
                    <li>IGA - 1,400 stores with 7% of the market</li>
                    <li>Woolworths - 1,024 stores with 37% of the market</li>
                    <li>Coles - 807 stores with 28% of the market</li>
                    <li>Others have 18% of market share</li>
                </ul>
            </Text>
            <Text fontSize={FontSize.large}>
                <p>The market share of the two major players has marginally declined over the past five years, mostly
                    due to the growth of ALDI and Costco</p>

                <p>ALDI’s growth has been slow over recent years and will continue, opening more stores across Australia increasing its market share, it
                    also continues to record strong growth in customer penetration</p>

                <p>ALDI Australia plans to open more stores in urban locations and launch an e-commerce offer, to
                    underpin the next stage of growth</p>

                <p>
                    ALDI is targeting a total of <Highlight>839</Highlight> stores beyond 2030, which represents an
                    increase of <Highlight>42%</Highlight> on current numbers
                </p>

                <p>ALDI maintains its fundamental point of difference will continue to be low prices</p>
            </Text>
        </Frame>
    );
}
