import {paramCase} from "param-case";

type KeyOfCSSStyleDeclaration = keyof CSSStyleDeclaration;

export const toCSS = (styles: Partial<CSSStyleDeclaration>, ...removeProperties: KeyOfCSSStyleDeclaration[]) => {

    // Remove properties
    removeProperties.forEach((item) => {
        delete styles[item as KeyOfCSSStyleDeclaration];
    });

    const css = Object.keys(styles)
        .map((item) => {
            return `${paramCase(item)}:${styles[item as unknown as keyof CSSStyleDeclaration]};`;
        })
        .join(";");

    return css.charAt(css.length) !== ";" ? `${css};` : css;
};
